import moment from 'moment'
import React from 'react'
import { Translation } from 'react-i18next'
import pickBy from 'lodash/pickBy'
import i18n from 'services/i18n'
import { parseCurrency } from './currency'

export const getSeasonalityDisplay = scoreValue => {
  if (scoreValue == 1) {
    return (
      <Translation ns="common">
        {t => <span>{t('common:keyword.verylow', 'Very Low')}</span>}
      </Translation>
    )
  }
  if (scoreValue == 2) {
    return (
      <Translation ns="common">
        {t => <span>{t('common:keyword.low', 'Low')}</span>}
      </Translation>
    )
  }
  if (scoreValue == 3) {
    return (
      <Translation ns="common">
        {t => <span>{t('common:keyword.medium', 'Medium')}</span>}
      </Translation>
    )
  }
  if (scoreValue == 4) {
    return (
      <Translation ns="common">
        {t => <span>{t('common:keyword.high', 'High')}</span>}
      </Translation>
    )
  }
  if (scoreValue == 5) {
    return (
      <Translation ns="common">
        {t => <span>{t('common:keyword.veryhigh', 'Very High')}</span>}
      </Translation>
    )
  }
}

export const getCompetitionDisplay = value => {
  const scoreValue = Math.round(value)

  if ([0, 1, 2].includes(scoreValue)) {
    return (
      <Translation ns="common">
        {t => <span>{t('common:keyword.verylow', 'Very Low')}</span>}
      </Translation>
    )
  }
  if ([3, 4].includes(scoreValue)) {
    return (
      <Translation ns="common">
        {t => <span>{t('common:keyword.low', 'Low')}</span>}
      </Translation>
    )
  }
  if ([5, 6].includes(scoreValue)) {
    return (
      <Translation ns="common">
        {t => <span>{t('common:keyword.medium', 'Medium')}</span>}
      </Translation>
    )
  }
  if ([7, 8].includes(scoreValue)) {
    return (
      <Translation ns="common">
        {t => <span>{t('common:keyword.high', 'High')}</span>}
      </Translation>
    )
  }
  if ([9, 10].includes(scoreValue)) {
    return (
      <Translation ns="common">
        {t => <span>{t('common:keyword.veryhigh', 'Very High')}</span>}
      </Translation>
    )
  }
}

export const getDisplayScore = (attribute, value) => {
  if (attribute === 'competition') {
    return getCompetitionDisplay(value / 10)
  }
  if (attribute === 'classification5') {
    return getSeasonalityDisplay(value)
  }
  if (attribute === 'peakWeek') {
    return moment(value, 'MMM DD').format('MMMM')
  }
  if (['avgUnitsSold', 'estimatedExactSearchVolume'].includes(attribute)) {
    return Math.round(value).toLocaleString()
  }
  return Math.round(value / 10).toLocaleString()
}

// The backend should search using a minimum of 151 but the frontend should show 151+
export const getSearchVolumeDisplay = (value = 0, displayMax) => {
  if (value <= 151) {
    return `150+`
  }
  if (value >= displayMax) {
    return `${displayMax.toLocaleString()}+`
  }

  return value.toLocaleString()
}

export const getCurrencyDisplay = (value, currencyCode, displayMax) => {
  if (value < displayMax) return parseCurrency(value, currencyCode)
  return `${parseCurrency(value, currencyCode)}+`
}

export const translateEaseOfRank = value => {
  switch (true) {
    case value >= 0 && value <= 30:
      return i18n.t('common:keyword.veryDifficult', 'Very Difficult')
    case value >= 31 && value <= 50:
      return i18n.t('common:keyword.difficult', 'Difficult')
    case value >= 51 && value <= 69:
      return i18n.t('common:keyword.somewhatDifficult', 'Somewhat Difficult')
    case value >= 70 && value <= 89:
      return i18n.t('common:keyword.moderate', 'Moderate')
    case value >= 90 && value <= 100:
      return i18n.t('common:keyword.Easy', 'Easy')
    default:
      return ''
  }
}

export const translateInListing = value => {
  switch (value) {
    case -1:
      return '-'
    case 0:
      return i18n.t(
        'rank_tracker:rankTrackerV2.KeywordDetails.ProductInfo.notInListing',
        'Not in Listing'
      )
    case 1:
      return i18n.t(
        'rank_tracker:rankTrackerV2.KeywordDetails.ProductInfo.partialInListing',
        'Partial'
      )
    default:
      return i18n.t(
        'rank_tracker:rankTrackerV2.KeywordDetails.ProductInfo.inListing',
        'In Listing'
      )
  }
}

export const translateMonth = month => {
  switch (month) {
    case 'January':
      return (
        <Translation ns="common">
          {t => <span>{t('common:keyword.month.January', 'January')}</span>}
        </Translation>
      )
    case 'February':
      return (
        <Translation ns="common">
          {t => <span>{t('common:keyword.month.February', 'February')}</span>}
        </Translation>
      )
    case 'March':
      return (
        <Translation ns="common">
          {t => <span>{t('common:keyword.month.March', 'March')}</span>}
        </Translation>
      )
    case 'April':
      return (
        <Translation ns="common">
          {t => <span>{t('common:keyword.month.April', 'April')}</span>}
        </Translation>
      )
    case 'May':
      return (
        <Translation ns="common">
          {t => <span>{t('common:keyword.month.May', 'May')}</span>}
        </Translation>
      )
    case 'June':
      return (
        <Translation ns="common">
          {t => <span>{t('common:keyword.month.June', 'June')}</span>}
        </Translation>
      )
    case 'July':
      return (
        <Translation ns="common">
          {t => <span>{t('common:keyword.month.July', 'July')}</span>}
        </Translation>
      )
    case 'August':
      return (
        <Translation ns="common">
          {t => <span>{t('common:keyword.month.August', 'August')}</span>}
        </Translation>
      )
    case 'September':
      return (
        <Translation ns="common">
          {t => <span>{t('common:keyword.month.September', 'September')}</span>}
        </Translation>
      )
    case 'October':
      return (
        <Translation ns="common">
          {t => <span>{t('common:keyword.month.October', 'October')}</span>}
        </Translation>
      )
    case 'November':
      return (
        <Translation ns="common">
          {t => <span>{t('common:keyword.month.November', 'November')}</span>}
        </Translation>
      )
    case 'December':
      return (
        <Translation ns="common">
          {t => <span>{t('common:keyword.month.December', 'December')}</span>}
        </Translation>
      )
    default:
      return month
  }
}

export const convertRangeFilterStringsToNumbers = filters => {
  const minMaxFiltersData = pickBy(filters, value => value.type === 'range')
  const minMaxFilters = Object.entries(minMaxFiltersData).map(
    ([key, value]) => [
      key,
      {
        ...value,
        min: parseInt(value.min, 10) || 0,
        max: parseInt(value.max, 10) || undefined
      }
    ]
  )
  const updatedMinMaxFilters = Object.fromEntries(minMaxFilters)
  return { ...filters, ...updatedMinMaxFilters }
}
