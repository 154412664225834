// TODO: move mapFiltersToApiParams into this file during cleanup phase
import { mapFiltersToApiParams } from 'helpers/keyword_engine'

const isAsinSearch = tags => {
  return Array.isArray(tags) && tags?.length
}

export const getAllVariantsValue = (tags, allVariantsState) => {
  return isAsinSearch(tags) ? allVariantsState : false
}

export const buildRequestParams = ({
  asins = null,
  allVariants,
  filters,
  currentPage,
  tags,
  searchTerm,
  country,
  skipCounter = false,
  include_keyword_lists
}) => {
  const pageSize = 100
  const startingPoint = (currentPage - 1) * pageSize
  // Our Elastic Search (ES) is currently configured to not return results from the `9751th` element or later. So we limit this number on the client while that ES configuration exists.

  const MAX_ITEMS_SUPPORTED_ES = 9750
  const from =
    startingPoint > MAX_ITEMS_SUPPORTED_ES
      ? MAX_ITEMS_SUPPORTED_ES
      : startingPoint
  const requestData = {
    ...mapFiltersToApiParams(filters),
    skipCounter,
    all_variants: getAllVariantsValue(tags, allVariants),
    country,
    from,
    page_size: pageSize
  }

  if (asins) {
    requestData.asins = asins
  }
  if (include_keyword_lists) {
    requestData.include_keyword_lists = true
  }

  if (isAsinSearch(tags) && !asins) {
    requestData.asins = tags.map(nextAsin => nextAsin.toUpperCase()).join(', ')
  } else if (typeof searchTerm === 'string' && searchTerm !== '') {
    requestData.search_terms = [
      searchTerm
        .trim()
        .toLowerCase()
        .replace(/,/g, ' ')
    ]
  }

  return requestData
}
