import { DEFAULT_PLAN_FEATURE_LIMITS } from 'constants/feature_limit_modal'

import { capitalize } from 'helpers/strings'
import {
  currentGroupToUpgradeGroupMap,
  legacyGroupToNewGroupMap
} from 'helpers/checkout'

export const graphLimitFormatter = days => {
  if (days === -1) return 'Unlimited'

  const months = Math.ceil(days / 30)
  if (months >= 12) return `${Math.floor(months / 12)} Year`

  return months === 1 ? '1 Month' : `${months} Months`
}

export const listingLimitFormatter = limit => {
  if (!limit || limit === 0) return 'No Access'
  if (limit === -1) return 'Unlimited'

  return `${limit} ${limit === 1 ? 'Listing' : 'Listings'}`
}

export const searchLimitFormatter = (feature, key = 'featureLimit') => {
  if (!feature?.[key] || feature[key] === 0) return 'No Access'
  if (feature[key] === -1) return 'Unlimited'

  return feature[key]
}

export const formatLimit = (
  feature,
  key = 'featureLimit',
  intervalKey = 'intervalTimeframe'
) => {
  const limit = searchLimitFormatter(feature, key)

  if (
    limit === 'No Access' ||
    limit === 'Unlimited' ||
    !feature[intervalKey] ||
    feature[intervalKey] === 'indefinite'
  ) {
    return limit
  }

  return `${limit} / ${capitalize(feature[intervalKey])}`
}

export const defaultGroupUpgradeInfo = (currentGroup, isLegacy) => {
  const {
    suite,
    growthAccelerator,
    pro,
    brandOwner
  } = DEFAULT_PLAN_FEATURE_LIMITS

  let features
  if (isLegacy) {
    features = currentGroup?.toLowerCase() === 'suite' ? pro : suite
  } else {
    features =
      currentGroup?.toLowerCase() === 'suite' ? brandOwner : growthAccelerator
  }

  return features
}

export const getUpgradePlanDetails = (permissions, currentGroup) => {
  return {
    name: legacyGroupToNewGroupMap(currentGroupToUpgradeGroupMap(currentGroup)),
    rankTracker: formatLimit(
      permissions?.rank_tracker,
      'tracked_keywords_limit'
    ),
    productTrackerLimit: formatLimit(
      permissions.product_tracker,
      'tracker_limit',
      'interval_timeframe'
    ),
    productDatabaseSearches: formatLimit(
      permissions?.product_database,
      'limit',
      'interval_timeframe'
    ),
    keywordScoutSearches: formatLimit(
      permissions?.keyword_scout,
      'limit',
      'interval_timeframe'
    ),
    opportunityFinderSearches: formatLimit(
      permissions?.niche_hunter,
      'limit',
      'interval_timeframe'
    ),
    historicalProductTrackerData: graphLimitFormatter(
      permissions?.product_tracker?.graph_days_limit
    ),
    historicalKeywordScoutData: graphLimitFormatter(
      permissions?.keyword_scout?.graph_days_limit
    ),
    listingBuilder: listingLimitFormatter(
      permissions?.listing_builder.listings_limit
    ),
    aiAssistChat:
      permissions?.ai_features?.chat_requests === -1 ? 'Advanced' : 'Limited',
    aiAssist: formatLimit(
      permissions?.ai_features,
      'limit',
      'interval_timeframe'
    )
  }
}
