import React from 'react'
import some from 'lodash/some'
import values from 'lodash/values'
import isEmpty from 'lodash/isEmpty'
import { Translation, Trans } from 'react-i18next'
import { ZIPCODES, ZIPCODE_EXCLUSIONS } from '../constants/zipcodes'

export const emptyTextFormValidation = value => {
  const valid = !!value
  const message = valid ? (
    ''
  ) : (
    <Trans i18nKey="generic:MustNotBeEmpty">Must not be empty</Trans>
  )
  return {
    valid,
    message
  }
}

const textFormValidation = (value, length) => {
  const valid = value && value.length > length
  const message = valid ? (
    ''
  ) : (
    <Translation ns="registrations">
      {t =>
        t(
          'registrations:RegistrationForm.lengthError',
          'Must be more than {{length}} characters',
          { length }
        )
      }
    </Translation>
  )
  return {
    valid,
    message
  }
}

const presenceValidation = value => {
  const valid = value && !isEmpty(value)
  const message = valid ? (
    ''
  ) : (
    <Trans i18nKey="generic:Required">Required</Trans>
  )
  return {
    valid,
    message
  }
}

const postalCodeValidation = ({ zip, country }) => {
  if (country && ZIPCODE_EXCLUSIONS.includes(country)) {
    return {
      valid: true,
      message: ''
    }
  }

  const regex = new RegExp(ZIPCODES[country], 'i')
  const valid = regex.test(zip)
  const message =
    !country || valid ? (
      ''
    ) : (
      <Trans i18nKey="registrations:RegistrationForm.zipError">
        Invalid zip
      </Trans>
    )
  return {
    valid,
    message
  }
}

const countryValidation = value => {
  const valid = value && value.length === 2
  const message = valid ? (
    ''
  ) : (
    <Trans i18nKey="registrations:RegistrationForm.countryError">
      e.g. &quot;US&quot; or &quot;CA&quot;
    </Trans>
  )
  return {
    valid,
    message
  }
}

const passwordValidation = value => {
  const tooShort = !value || value.length <= 8

  if (tooShort) {
    return {
      valid: false,
      message: (
        <Trans i18nKey="registrations:RegistrationForm.passwordLengthError">
          Must be more than 8 characters
        </Trans>
      )
    }
  }

  const noNumber = !/\d/.test(value)

  if (noNumber) {
    return {
      valid: false,
      message: (
        <Trans i18nKey="registrations:RegistrationForm.passwordNoNumberError">
          Must contain at least 1 number
        </Trans>
      )
    }
  }

  return {
    valid: true,
    message: ''
  }
}

const emailFormValidation = email => {
  // eslint-disable-next-line
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const valid = emailRegex.test(email)
  const message = valid ? (
    ''
  ) : (
    <Trans i18nKey="registrations:RegistrationForm.emailInvalid">
      Invalid email address
    </Trans>
  )
  return {
    valid,
    message
  }
}

export const validateInputField = (field, input) => {
  switch (field) {
    case 'firstName':
    case 'first_name':
    case 'lastName':
    case 'last_name':
    case 'country':
    case 'keyword':
      return textFormValidation(input, 1)
    case 'email':
      return emailFormValidation(input)
    case 'password':
    case 'newPassword':
      return passwordValidation(input)
    case 'oldPassword':
      return emptyTextFormValidation(input)
    case 'sellerId':
      // seller_id can vary between 12-14 characters
      return textFormValidation(input, 11)
    case 'scEmail':
      return emailFormValidation(input)
    case 'mwsAuthToken':
      return textFormValidation(input, 44)
    case 'asin':
      return textFormValidation(input, 6)
    case 'radio':
    case 'presence':
      return presenceValidation(input)
    case 'address_line1':
    case 'address_city':
    case 'address_state':
      return presenceValidation(input)
    case 'address_zip':
      return postalCodeValidation(input)
    case 'address_country':
      return countryValidation(input)
    default:
      break
  }
}

export const hasErrors = fields => {
  return some(values(fields), value => !isEmpty(value))
}

export const hasEmptyFields = fields => {
  return some(values(fields), value => isEmpty(value))
}

export const getNumberValue = value => {
  const isNaN =
    Number.isNaN(value) ||
    Number.isNaN(Number.parseInt(value, 10)) ||
    Number.isNaN(Number(value))
  return isNaN ? '' : Number(value)
}
