export const isFeatureEnabled = (feature, flagData) => {
  if (!flagData || !feature) {
    return false
  }

  if (!Array.isArray(feature)) {
    return !!feature && !!flagData[feature]
  }

  return feature.every(f => !!flagData[f])
}

export const someFeatureEnabled = (features, flagData) => {
  return (
    !!features && features.some(feature => isFeatureEnabled(feature, flagData))
  )
}
