// Only pass a filename to download if you want to download the file,
// otherwise it'll just open it in the browser
export const viewOrDownloadFile = (data, type, download) => {
  const blob = new Blob([data], { type })

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    return window.navigator.msSaveOrOpenBlob(blob)
  }

  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'

  if (download) {
    link.download = download
  }

  document.body.appendChild(link)
  link.click()

  // Delay for FF
  setTimeout(() => {
    window.URL.revokeObjectURL(data)
  }, 100)
}

export const bytesToMb = bytes => (bytes / 1024 ** 2).toFixed(2)
